import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import SEO from "../components/seo"

const max = {
  width: '100%',
  height: '100%'
}

const MapPage = ({ data }) => (
  <Layout>
    <SEO title="Map" />
    <Zoom>
      <Image style={max} fluid={data.illustration.childImageSharp.fluid} />
    </Zoom>
  </Layout>
)

export default MapPage

export const query = graphql`
  query {
    illustration: file(relativePath: { eq: "page_map.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 5000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
